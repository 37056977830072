// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/home/cookscape-final.png';
const Footer = () => {
  return (
    
<>
<div className='container-fluid qoute-background'>
  <p className='text-center qoute-para'>You dream It, we design It</p>
  <h2 className='text-center qoute-title'>Let us help you design a <br/>
  luxurious home</h2>
  
  <div class="text-center">
  <Link to="/interior-designer-in-chennai"> <button className='qoute-button'>Get qoute</button></Link>
</div>
</div>
    <div className='container-fluid footer'>
    <div className='row'>
      <div className='col-md-3'>
      <Link to="/">
      <img src={logo} alt="Cookscape-logo" class="logo1"/>
        </Link>
        <p className='footer-para'>Cookscape Home Interiors, an exclusive Indian brand, meets all the functional challenges of Designs with its finest finish.</p>
        <p class="phone-icon"><i class="fa fa-volume-control-phone" aria-hidden="true"></i>96000 05679</p> 
        <div class="social-container">
    <ul class="social-icons1">
    <Link to="https://www.instagram.com/cookscape_interior/"> <li><a href="#"><i class="fa fa-instagram"></i></a></li></Link>
    <Link to="https://www.youtube.com/@CookscapeInterior/videos">  <li><a href="#"><i class="fa fa-youtube-play"></i></a></li></Link>
    <Link to="https://x.com/Cookscape1">   <li><a href="#"><i class="fa fa-twitter"></i></a></li></Link>
    <Link to="https://www.facebook.com/cookscape/">  <li><a href="#"><i class="fa fa-facebook"></i></a></li></Link>
    </ul>
    </div>
      </div>
      <div className='col-md-3'>
      <h3 class="footer-title">ADDRESS</h3>
      <h3 class="footer-title mt-5">EXPERIENCE STUDIO</h3>
      <p className='footer-para'>ANNA SALAI #517/338, Anna Salai, Nadanam, Chennai 35</p>

      <h3 class="footer-title mt-5">OMR</h3>
      <p className='footer-para'>#3/33, Thoraipakkam,
      Rajiv Gandhi Salai, OMR, Chennai 119.</p>
       </div>
      <div className='col-md-3'>
      <h3 class="footer-title">PORUR</h3>
      <p className='footer-para'>#64, 1st floor, Poonamalle Road, Porur Chennai 116.</p>

      <h3 class="footer-title mt-5">COIMBATORE</h3>
      <p className='footer-para'>1095, 1st Floor, Mettupalayam Road, Vadakovai, RS Puram West,Next to Dr. Agawal Eye Hospital Coimbatore, 641002.</p>
      </div>
      <div className='col-md-3'>
      <h3 class="footer-title">BENGALURU</h3>
      <p className='footer-para'>1565, 1st sector HSR layout, Service road,
      outer ring road, Bangalore, 560102.</p>

      <h3 class="footer-title mt-5">VIJAYAWADA</h3>
      <p className='footer-para'>59-8-3, Potluri Venkteswara Rao St, Gayathri Nagar, Vijayawada, 520008.</p>
      </div>
      </div>
      <div class="copyright">
      <p class="copyright-para"> © Copyright Orchid Designs Pvt Ltd 2025. All rights reserved. Cookscape is an Orchid Designs Product.</p>
    </div>
    </div>
    </>
  );
};
export default Footer;
