import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import bg2 from '../images/home/cookscape-banner-1.webp';
import bg3 from '../images/home/interior-design-cookscape.png';
import bg4 from '../images/home/testimonial1.png';
import bg5 from '../images/home/testimonial2.png';
import bg1 from '../images/home/interior-design.webp';
import img1 from '../images/services/bedroom-1.png';
import img2 from '../images/home/interior-design-ideas.webp';
import pimg1 from '../images/home/modularkitchen.png';
import pimg2 from '../images/home/Wardrobestorage .webp';
import pimg3 from '../images/home/entertainmentunit .webp';
import pimg4 from '../images/home/cookscape- pooja unit.webp';
import pimg5 from '../images/home/kids-furniture.png';
import pimg6 from '../images/home/cookscape-crockery.png';
import traditional from '../images/home/traditionalrubberwood .webp';
import tuffglass from '../images/home/tuffglass .webp';
import veneer from '../images/home/veneer .webp';
import amaryllis from '../images/home/amaryllis .webp';
import exotica from '../images/home/exotica .webp';
import surabhi from '../images/home/surabhi .webp';
const Home = () => {
  return (
   <>
<Helmet>
<title> Cookscape - Interior Designer in Chennai, Modular Kitchen Designer in Chennai, Wardrobe Designer in Chennai, Home Renovation in Chennai, TV Unit in  Chennai, Kitchen Cabinet Designs In Chennai  </title>
<meta name="keywords" content="interior designers in Chennai, modular kitchen in Chennai, interior decorators in Chennai, kitchen designers in Chennai, bedroom wardrobe designers in Chennai, home renovation in Chennai, remodeling in Chennai, kitchen renovators in Chennai, living room interior decor in Chennai, dining room interior designers in Chennai, home interior decors in Chennai, interior design company in Chennai, interior design ideas in Chennai, kitchen interior ideas in Chennai, pooja room interior designers in Chennai, kitchen cabinet designs in Chennai"/>
<meta name="description" content="Cookscape - your one-stop solution for home interiors in Chennai, Modular kitchens, Wardrobes, Pooja units, TV units, and Kids' furniture. Designed with Love and Care.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com"></link>
<meta name="google-site-verification" content="vdcMRQBg7oBqUjxKHp6QZtnMdh_S_egToytdKxjydT4" />
</Helmet>

<div className='conteiner-fluid slider'>
<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" data-interval="500">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item before active">
            <img src={bg3} alt="living-space" class="banner"/>
              <div class="main-section">
              <p class="main-para text-center">Are You Ready To Change Your</p>
              <h1 class="main-title">Home To 'Dream Home'</h1>
              </div>
        </div>
        <div class="carousel-item before">
          <img src={bg2} alt="interior" class="banner"/>
            <div class="main-section">
              <p class="main-para text-center">A One Stop Solution For Your Home Interiors</p>
              <h1 class="main-title">Interiors And Beyond</h1>
            </div>
        </div>
        <div class="carousel-item before">
          <img src={bg1} alt="interior-design" class="banner"/>
            <div class="main-section">
              <p class="main-para text-center">All You Can Think About</p>
              <h1 class="main-title text-center">Home Interiors Design</h1>
            </div>
        </div>
      </div>
</div>
</div>
<div className='container-fluid background'>
    <div className='container'>
          <div className="row">
            <div className="col-md-6">
                <div className="aboutus-content mt-5">
                    <h1 className="aboutus-title">Why choose <span className='cookscape'>Cookscape?</span></h1>
                    <p className="experience">30+ Years Experience</p>
                    <p className="aboutus-para">
                    We are India's 2nd largest Home Interiors Designer store, located in Chennai, offering expert services to transform your living space. Whether you’re redesigning your home or creating a brand-new look, our team is here to craft unique and functional designs tailored just for you.
                    Your home should reflect your personality and style. That’s why we work closely with you to design a space that’s not only beautiful, but also practical. From planning to finishing touches, we handle every detail to ensure you are satisfied with the design.</p>
                  </div>
            </div>    
            <div className="col-md-6">
              <div className='box'>
              <p className="box-icon"><i class="fa fa-building" aria-hidden="true"></i></p>
                <h2 className="box-title">3 Decades of Experience</h2>
                <p className="box-para">30+ years of experience in providing the best Home Interiors with quality as our top priority.​</p>
              </div>
               <img src={img1} alt="cookscape-floor-planning" class="img-fluid"/>
            </div>          
          </div>
        </div>
</div>
<div className='container-fluid background'>
    <div className="container">
        <h2 className='text-center text-dark headings'>Interior Services</h2>
          <div className="container">
            <div className="row">
                <div className="col-md-4 p-3">
                    <div className="product-image">
                        <img src={pimg1} alt="cookscape-Modular-kitchen" class="image"/>
                        <div className="overlay">
                            <div className="text">Modular Kitchen</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 p-3">
                    <div className="product-image">
                        <img src={pimg2} alt="cookscape-wardrobe-storage" class="image"/>
                        <div className="overlay">
                            <div className="text">Wardrobe Storage System</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 p-3">
                    <div className="product-image">
                        <img src={pimg3} alt="cookscape-TV-unit" class="image"/>
                            <div className="overlay">
                                <div className="text">TV Unit</div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container mt-3">
            <div className="row">
                <div className="col-md-4 p-3">
                    <div className="product-image">
                            <img src={pimg4} alt="cookscape-pooja-unit" class="image"/>
                        <div className="overlay">
                            <div className="text">Pooja Unit</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 p-3">
                    <div className="product-image">
                            <img src={pimg5} alt="cookscape-kids-furniture" class="image"/>
                        <div className="overlay">
                            <div className="text">Kids Furniture</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 p-3">
                    <div className="product-image">
                            <img src={pimg6} alt="cookscape-crockery-unit" class="image"/>
                        <div className="overlay">
                            <div className="text">
                            Crockery Unit</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div> 
</div>
<div className='container-fluid background'>
        <div className="container p-5">
           <h2 className='text-center text-dark headings'>Why Choose us</h2>
        <div className="row services-row">
            <div className="col-md-4 services-col">
                <p className="icon"><i class="fa fa-cube" aria-hidden="true"></i></p>
                <h2 className="services-title-3">3 Decades of Experience</h2>
                <p className="services-para">30+ years of experience in providing the best Home Interiors with quality as our top priority.​</p>
            </div>
            <div className="col-md-4 services-col">
                <p className="icon"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></p>
                <h2 className="services-title-3">10 Years Guarantee</h2>
                <p className="services-para">We provide 10 Years Guarantee, which is the longest any company will offer, with our in-house manufacturing.​</p>
            </div>
            <div className="col-md-4 services-col">
                <p className="icon"><i class="fa fa-building" aria-hidden="true"></i></p>
                <h2 className="services-title-3">27,360 Projects</h2>
                <p className="services-para">With 27,360+ projects, we transform any space with Precision, Creativity, and Expertise.​</p>
            </div>
        </div>

        <div className="row">
            <div className="col-md-4 services-col">
                <p className="icon"><i class="fa fa-user-plus" aria-hidden="true"></i></p>
                <h2 className="services-title-3">Leading Architects</h2>
                <p className="services-para">Our Skilled Architects blend innovative design and practical solutions to Create Beautiful and Functional Spaces.​</p>
            </div>
            <div className="col-md-4 services-col">
                <p className="icon"><i class="fa fa-phone-square" aria-hidden="true"></i></p>
                <h2 className="services-title-3">24/7 Support</h2>
                <p className="services-para">Our Support team is available 24/7 to assist with Queries and ensure a smooth experience.​</p>
            </div>
            <div className="col-md-4 services-col">
                <p className="icon"><i class="fa fa-sticky-note" aria-hidden="true"></i></p>
                <h2 className="services-title-3">Free Consultation</h2>
                <p className="services-para">Get Expert advice for free! We offer Free Consultation
                to help you plan the perfect design for your space.​</p>
            </div>
        </div>
    </div>
</div>
<div className="container-fluid callback">
        <h2 className='text-center text-white headings'>FAQ</h2>
       <div className='container'>
          <div className="row">
            <div className="col-md-6">
            <img src={img2} alt="cookscape-interior-design-ideas" class="faq-image"/>
            </div>
            <div className="col-md-6">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-heading1">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse1" aria-expanded="false" aria-controls="panelsStayOpen-collapse1">
                    What is Interior Design?</button>
                  </h2>
                  <div id="panelsStayOpen-collapse1" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading1">
                    <div className="accordion-body">
                    Interior design is the process of planning and designing the layout and decor of a space to make it both functional and aesthetically pleasing. It involves selecting furniture, color schemes, lighting, and decorative elements that complement the purpose of the space. Interior designers aim to enhance the environment by improving its flow, comfort, and visual appeal.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-heading2">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse2" aria-expanded="false" aria-controls="panelsStayOpen-collapse2">
                    How do I choose an Interior Designer?</button>
                  </h2>
                  <div id="panelsStayOpen-collapse2" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading2">
                    <div className="accordion-body">
                    When selecting an interior designer, it’s important to review their portfolio to ensure their design style aligns with your vision. You should also check Client testimonials and references to gauge their professionalism and work quality. Communication is key- make sure the designer listens to your ideas, understands your needs, and can work within your budget and timeline.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-heading3">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse3" aria-expanded="false" aria-controls="panelsStayOpen-collapse3">
                    What factors should I consider before doing my Home Interiors?</button>
                  </h2>
                  <div id="panelsStayOpen-collapse3" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading3">
                    <div className="accordion-body">
                    Before starting a project, consider your budget and the overall style you want to achieve. Think about the functionality of the space- how you will use it and the flow of movement. It’s also important to decide on materials, colors, and furniture preferences. Setting clear goals will help you guide the design process and ensure the result meets your expectations.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-heading4">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse4" aria-expanded="false" aria-controls="panelsStayOpen-collapse4">
                      How much does Interior Designs cost?</button>
                    </h2>
                    <div id="panelsStayOpen-collapse4" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading4">
                      <div className="accordion-body">
                      The cost of interior design depends on various factors, including the size of the space, the complexity of the design, and the designer’s experience. Some designers charge a flat fee, while others work on an hourly basis or as a percentage of the overall project cost. Additional expenses may include furniture, decor, and materials. It’s always best to discuss and agree on pricing before starting the project to avoid surprises.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-heading5">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse5" aria-expanded="false" aria-controls="panelsStayOpen-collapse5">
                        How long does an Interior Design project take?
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapse5" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading5">
                      <div className="accordion-body">
                      The timeline for an interior design project varies based on the scope and complexity of the work. Simple redesigns or room makeovers may take a few weeks, while larger projects involving custom furniture or major renovations can take several months. It’s important to communicate with the designer about expected timelines and any potential delays to ensure the project stays on track.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-heading6">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse6" aria-expanded="false" aria-controls="panelsStayOpen-collapse6">
                      What is the difference between Interior Design and Interior Decoration?</button>
                    </h2>
                    <div id="panelsStayOpen-collapse6" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-heading6">
                      <div className="accordion-body">
                      Interior design focuses on the overall functionality and structure of a space, including space planning, lighting, and material selection. It may involve collaborating with Architects and Contractors. Interior decoration, on the other hand, deals primarily with aesthetics, such as selecting furniture, fabrics, and accessories to beautify a space without altering its structure.
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
       </div>
</div>
<div className='container-fluid background p-3'>
    <div class="container">
        <div class="row ">
        <div class="col-md-3 col-sm-6">
          <h3 class="client">Customized Design</h3>
            <div class="progress green">
                <span class="progress-left">
                    <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                    <span class="progress-bar"></span>
                </span>
                <div class="progress-value">98%</div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <h3 class="client">Product Quality</h3>
            <div class="progress yellow">
                <span class="progress-left">
                    <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                    <span class="progress-bar"></span>
                </span>
                <div class="progress-value">100%</div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <h3 class="client">Client Satisfication</h3>
            <div class="progress pink">
                <span class="progress-left">
                    <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                    <span class="progress-bar"></span>
                </span>
                <div class="progress-value">100%</div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <h3 class="client">24/7 Support</h3>
            <div class="progress green">
                <span class="progress-left">
                    <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                    <span class="progress-bar"></span>
                </span>
                <div class="progress-value">98%</div>
            </div>
        </div>
    </div>
  </div>
</div>
<div className='container-fluid work'>
  <div className='row'>
    <div className='col-md-3'>
      <p className='work-para'>HOW WE WORK</p>
      
      <h2 className='work-title'>3 Easy steps to get interior design</h2>
      <p className='work-para1'>Well-designed and customized interior designs can be obtained in 3 easy steps</p>
    </div>
    <div className='col-md-3'>
      <div className='work-image'>
      <p class="work-icon"><i class="fa fa-check-square-o" aria-hidden="true"></i></p>
        <h2 className='work-inside-title'>Explore Product</h2>
        <p className='work-inside-para'>Explore Our Projects & Meet design team</p>
      </div>
      </div>
      <div className='col-md-3'>
      <div className='work-image'>
      <p class="work-icon"><i class="fa fa-thumbs-up" aria-hidden="true"></i></p>
        <h2 className='work-inside-title'>Customize Design</h2>
        <p className='work-inside-para'>Customize Your Design & Hassle Free Installation</p>
      </div>
      </div>
      <div className='col-md-3'>
      <div className='work-image'>
      <p class="work-icon"><i class="fa fa-calendar" aria-hidden="true"></i></p>
        <h2 className='work-inside-title'>Place The Order</h2>
        <p className='work-inside-para'>Place The Order And Delivery In 45 Days</p>
      </div>
      </div>
  </div>

</div>

<div className='container-fluid background'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-6'>
          <p className='variant-para'>Interior Designs</p>
        <h3 className='variant-title'>View Our Interior Design Variants</h3>
        </div>
        <div className='col-md-6'>
        <p className='variant-para1'>Well-designed, functional home furnishing solutions including modern kitchens, wardrobes, study units, and other modular woodwork, all designed to be functional and user-friendly.</p>
          </div>
      </div>
     
        <div class="row justify-content-center">
                    <div class="row justify-content-center">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                          <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="0" src={traditional} alt="cookscape-Traditional" class="faq-image"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                          <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="1" src={tuffglass} alt="cookscape-tuffglass" class="faq-image"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                          <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="2" src={veneer} alt="cookscape-veneer" class="faq-image"/>
                        </a>
                      </div>
                    <div class="row justify-content-center pt-3">
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                          <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="3" src={amaryllis} alt="cookscape-amaryllis" class="faq-image"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                          <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="4" src={exotica} alt="exotica" class="faq-image"/>
                        </a>
                        <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                          <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="5" src={surabhi} alt="cookscape-surabhi" class="faq-image"/>
                        </a>
                    </div>
                </div>
                <div class="modal fade" id="exampleLightbox" tabindex="-1" aria-labelledby="exampleLightboxLabel" aria-hidden="true">
                  <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleLightboxLabel">Our Interior Variants</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div id="lightboxExampleCarousel" class="carousel slide">
                                <div class="carousel-inner ratio ratio-16x9 bg-dark">
                                    <div class="carousel-item text-center active">
                                      <h2 className='variant-inside-title'>Traditional </h2>
                                      <p className='variant-inside-para'>Rubber wood - 18 MM Thick - 10years Guarantee</p>
                                        <img src={traditional} alt="cookscape-traditional" class="img-fluid"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                    <h2 className='variant-inside-title'>Tuff Gloss </h2>
                                    <p className='variant-inside-para'>HDHMR - Glossy - 10years Guarantee</p>
                                      <img src={tuffglass} alt="cookscape-tuffglass" class="img-fluid"/>
                                   </div>
                                    <div class="carousel-item text-center">
                                    <h2 className='variant-inside-title'>Veneer </h2>
                                    <p className='variant-inside-para'>BWP EXT TREATMENT Natural Laminate</p>
                                      <img src={veneer} alt="cookscape-veneer" class="img-fluid"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                    <h2 className='variant-inside-title'>Amaryllis </h2>
                                    <p className='variant-inside-para'>3 MM Frame - Glass - 10years Guarantee</p>
                                      <img src={amaryllis} alt="cookscape-amaryllis" class="img-fluid"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                    <h2 className='variant-inside-title'>Exotica </h2>
                                    <p className='variant-inside-para'>BWP Glossy - 10years Guarantee</p>
                                      <img src={exotica} alt="cookscape-exotica" class="img-fluid"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                    <h2 className='variant-inside-title'>Surabhi </h2>
                                    <p className='variant-inside-para'>BWP EXT TREATMENT - 10years Guarantee</p>
                                      <img src={surabhi} alt="cookscape-surabhi" class="img-fluid"/>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
</div>
<div className='container-fluid state'>
<div className='container'>
  <div className='row'>
    <div className='col-md-8'>
      <p className='work-para'>STATISTICS</p>
      
      <h2 className='work-title'>Cookscape Statistics</h2>
      <p className='work-para1'>It meets all functional challenges with a high level of aesthetics and finish.</p>
    </div>
      <div className='col-md-4'>
      <div className='row'>
        <div className='col-md-6 state-inside'>
          <div className='state-div'>
          <h2 className='state-no'>27,360</h2>
          <p className='state-para'>Happy Customer</p>
          </div>
          <div className='state-div'>
          <h2 className='state-no'>30</h2>
          <p className='state-para'>Years Experience</p>
          </div> 
        </div>
        <div className='col-md-6'>
        <div className='state-div'>
          <h2 className='state-no'>195</h2>
          <p className='state-para'>Renowed builders</p>
          </div>
          <div className='state-div'>
          <h2 className='state-no'>300</h2>
          <p className='state-para'>Leading Architect</p>
          </div>
          </div>
      </div>
      </div>
      </div>
  </div>

</div>
<div className='container testimonial'>
<div className='row'>
  <div className='col-md-6'>
  <p className='variant-para'>TESTIMONIALS</p>
  <h3 className='variant-title'>Here's what our satisfied <br/>
  customers are saying</h3>
  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" data-interval="1000">
      <div class="carousel-inner">
        <div class="carousel-item active">
            <img src={bg4} alt="living-space" class="testimonial-img"/>
             
        </div>
        <div class="carousel-item ">
          <img src={bg5} alt="interior" class="testimonial-img"/>
            
        </div>
      </div>
</div>
  </div>
  <div className='col-md-6 p-5'>
  <iframe width="100%" height="300px" src="https://www.youtube.com/embed/lZdLOZnSfXs?si=IznF1Lv8KEqISRja">
  </iframe>
    </div>
</div>
</div>
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
             <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
   </>
  );
};

export default Home;
